import {
  handleGaTracking,
  getDataLayer,
  currentComponentIsNextTo,
  currentComponentIsChildOf,
} from "../../../../../../utils/tracking";

class MercureHighlight extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "highlight";

  constructor(componentHost: HTMLElement) {
    super(componentHost);
    const { pageName } = getDataLayer();

    const buttons: NodeListOf<HTMLAnchorElement> =
      componentHost.querySelectorAll(".ace-button a");

    let bloc_name: string | null | undefined = null;
    let bloc_interaction: string | null | undefined = null;

    const titleElement: HTMLElement | null = componentHost.querySelector(
      "*[class^='ace-highlight__content--title']",
    );
    const titleString = titleElement?.innerText?.trim().toLowerCase();

    if (componentHost.dataset.tracking !== "ready") {
      buttons.length &&
        buttons.forEach((button) => {
          switch (pageName) {
            case "mercure::brand::homepage": {
              if (
                currentComponentIsNextTo(
                  componentHost,
                  "section-background-container",
                )
              ) {
                // top of the page
                bloc_name = "local guide";
                bloc_interaction = "see more adresses - main";
              } else {
                // bottom of the page
                bloc_name = "mercure store";
                bloc_interaction = "shop now";
              }
              break;
            }

            case "mercure::brand::mercureexperience": {
              bloc_name = titleString;
              bloc_interaction = button.innerText.trim().toLowerCase();
              break;
            }

            case "mercure::brand::corporate": {
              if (
                currentComponentIsNextTo(componentHost, "slideshow-carousel")
              ) {
                // top of the page
                bloc_name = titleString;
                bloc_interaction = "read article";
              } else if (
                currentComponentIsNextTo(componentHost, "cardeditorial")
              ) {
                // bottom of the page
                bloc_name = `content ${titleString}`;
                bloc_interaction = "discover brand";
              }
              break;
            }

            case "mercure::magazine::hub": {
              bloc_name = "content mercure experience";
              bloc_interaction = "discover brand";
              break;
            }

            case "mercure::magazine::category": {
              if (currentComponentIsChildOf(componentHost, "target")) {
                // top of the page
                bloc_name = "highlighted article";
                bloc_interaction = "read article";
              } else {
                // bottom of the page
                bloc_name = "content mercure experience";
                bloc_interaction = "discover brand";
              }

              break;
            }

            case "mercure::magazine::article": {
              bloc_name = "content mercure experience";
              bloc_interaction = "discover brand";
              break;
            }

            case "mercure::offers::hub": {
              bloc_name = "highlighted offer";
              bloc_interaction = "apply offer";
              break;
            }
          }

          if (bloc_name && bloc_interaction) {
            handleGaTracking({
              node: button,
              htmlEventType: CoreJS.DomEventConstants.CLICK,
              eventName: "bloc_interact",
              additionalData: { bloc_name, bloc_interaction },
            });
          }
        });

      // avoid to add multiple event listeners when the same component is used multiple times on the same page
      componentHost.dataset.tracking = "ready";
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  MercureHighlight.CLASS_NAMESPACE,
  MercureHighlight,
  true,
);
