const selectors = {
  dataLayer: "[data-cmp-datalayer]",
  roomInput: '[data-input="room"]',
  firstTextInput: "input[type=text]",
  optionsTriggerBtn: ".ace-core-booking-engine__options-trigger button",
  submitBtn: ".ace-core-booking-engine__footer .ace-button button[type=submit]",
  arrivalDates: ".ace-core-booking-engine__check-in .hydrated",
  departureDate: ".ace-core-booking-engine__check-out .hydrated",
  accomodationType:
    '.ahAutocomplete__input__hidden input[name="search.geoZone.geoZoneType"]',
  numberOfNights:
    '.core-booking-engine__date-search input[name="search.nightNb"]',
};

const events = {
  openAdvancedBookingForm: "open_advanced_booking_form",
  submitBookingForm: "submit_booking_form",
  errorBookingForm: "error_booking_form",
};

const errrorTypes = {
  blank: "blank",
  invalidValue: "invalid value",
};

const handleFocusButtonEvents = ({
  submitBtn,
  inputToFocus,
}: {
  submitBtn: HTMLButtonElement;
  inputToFocus: HTMLInputElement;
}) => {
  if (submitBtn) {
    submitBtn.addEventListener(
      CoreJS.DomEventConstants.KEY_DOWN,
      (event: KeyboardEvent) => {
        if (
          event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab &&
          !event.shiftKey &&
          window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg
        ) {
          event.preventDefault();
          inputToFocus.focus();
        }
      },
      false,
    );
  }
};

export { errrorTypes, selectors, events, handleFocusButtonEvents };
