import { handleGaTracking } from "../../../../../../utils/tracking";

class MercureFooter extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "footercontainer";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    handleGaTracking({
      node: componentHost.querySelector(".footercontainer .ace-calltoaction a"),
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "newsletter",
        bloc_interaction: "open newsletter form",
      },
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  MercureFooter.CLASS_NAMESPACE,
  MercureFooter,
  true,
);
