import {
  handleGaTracking,
  getDataLayer,
  currentComponentIsNextTo,
  currentComponentIsChildOf,
} from "../../../../../../../utils/tracking";

class MercureLoyalty extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "callout-container";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    const { pageName } = getDataLayer();

    const isAPI: boolean = componentHost.classList.contains(
      "callout-container-api",
    );
    const isEdito: boolean = !isAPI;

    const articles: NodeListOf<HTMLElement> = componentHost.querySelectorAll(
      ".ace-callout-component",
    );
    const ctaButton = componentHost.querySelector<HTMLElement>(".ace-button");
    const tabs: NodeListOf<HTMLElement> = componentHost.querySelectorAll(
      ".ace-callout-container-filters__tab-item button",
    );

    // ".parbase" as ancestor of experience-fragments only appears when targetting is enabled (only on hidden-prod (ex: https://aem-mercure.accor.com/en.html) and prod)
    const targetingIsEnabled = !!componentHost.closest<HTMLElement>(".parbase");
    const experienceFragmentAncestorClassName = targetingIsEnabled
      ? "parbase"
      : "experience-fragment";

    let eventName: string = "";
    let bloc_name: string = "";
    let bloc_name_for_tab_without_proper_heading: string | undefined = "";
    let dataToSendForArticle: object = {};
    let dataToSendForCta: object = {};
    let dataToSendForTab: object = {};

    // 0 - if tabs are here, we need to enrich DOM with filtered indexes
    if (tabs.length && componentHost.dataset.filteredIndexes !== "ready") {
      this.enrichFilteredItems(articles);
      componentHost.dataset.filteredIndexes = "ready";
    }

    if (componentHost.dataset.tracking !== "ready") {
      articles.length &&
        articles.forEach((article, articleIndex) => {
          // 1 - see where we are and fill data to send
          switch (pageName) {
            case "mercure::brand::homepage": {
              if (isEdito) {
                const ancestorContainer = componentHost.closest<HTMLElement>(
                  `.${experienceFragmentAncestorClassName}`,
                );

                if (
                  ancestorContainer &&
                  currentComponentIsNextTo(ancestorContainer, "heading")
                ) {
                  // offers
                  if (
                    currentComponentIsNextTo(
                      ancestorContainer?.previousElementSibling as HTMLElement,
                      experienceFragmentAncestorClassName,
                    )
                  ) {
                    bloc_name = "actual offers";
                    eventName = "bloc_interact";

                    dataToSendForArticle = {
                      bloc_name,
                      bloc_interaction: "see offer page",
                      bloc_type: `${this.getOfferID(article)}|${articleIndex + 1}`,
                    };

                    dataToSendForCta = {
                      bloc_name,
                      bloc_interaction: "see more offers",
                    };
                  } else if (
                    currentComponentIsNextTo(
                      ancestorContainer?.previousElementSibling as HTMLElement,
                      "map",
                    )
                  ) {
                    // destinations
                    bloc_name = "destinations";
                    eventName = "bloc_interact";

                    dataToSendForArticle = {
                      bloc_name,
                      bloc_interaction: article
                        .querySelector<HTMLElement>(".cmp-teaser__pretitle")
                        ?.innerHTML.trim(),
                      bloc_type: (articleIndex + 1).toString(),
                    };

                    dataToSendForCta = {
                      bloc_name,
                      bloc_interaction: "see more destinations",
                    };
                  } else if (
                    currentComponentIsNextTo(
                      ancestorContainer?.previousElementSibling as HTMLElement,
                      "highlight",
                    )
                  ) {
                    // "envolez vous + en savoir plus"

                    // considérer pretitle pour le titre d'article
                    bloc_name = "local guide";
                    eventName = "bloc_interact";
                    const articleTitle = article
                      .querySelector<HTMLElement>(".cmp-teaser__pretitle")
                      ?.innerText.trim();

                    dataToSendForArticle = {
                      bloc_name,
                      bloc_interaction: "see address page",
                      bloc_type: `${articleTitle}|${articleIndex + 1}`,
                    };

                    dataToSendForCta = {
                      bloc_name,
                      bloc_interaction: "see more addresses",
                    };
                  }
                }
              } else {
                bloc_name = "hotels flagships";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name,
                  bloc_interaction: "see hotel page",
                  bloc_type: `${this.getHotelRID(article)}|${articleIndex + 1}`,
                };
              }
              break;
            }

            case "mercure::magazine::hub": {
              if (isEdito) {
                eventName = "menu_inpage";

                if (
                  currentComponentIsChildOf(
                    componentHost,
                    "section-background-container",
                  )
                ) {
                  bloc_name = "dive into design";
                } else if (currentComponentIsNextTo(componentHost, "heading")) {
                  const previousElement =
                    componentHost.previousElementSibling as HTMLElement;
                  const followingElement =
                    componentHost.nextElementSibling as HTMLElement;

                  if (currentComponentIsNextTo(previousElement, "heading")) {
                    bloc_name = "taste the world";
                  } else if (
                    currentComponentIsNextTo(
                      previousElement,
                      "section-background-container",
                    )
                  ) {
                    if (followingElement.classList.contains("social-media")) {
                      bloc_name = "make positive impact";
                    } else {
                      bloc_name = "discover cultural treasures";
                    }
                  }
                }

                dataToSendForArticle = {
                  bloc_name,
                  menu_position: (articleIndex + 1).toString(),
                  menu_label: article
                    .querySelector<HTMLElement>(".cmp-teaser__pretitle")
                    ?.innerText.trim(),
                };

                dataToSendForCta = {
                  bloc_name,
                  bloc_interaction: "see more articles",
                };
              } else {
                dataToSendForArticle = {
                  bloc_name: "hotels flagships",
                  bloc_interaction: "see hotel page",
                  bloc_type: `${this.getHotelRID(article)}|${articleIndex + 1}`,
                };
              }
              break;
            }

            case "mercure::magazine::category": {
              if (isEdito) {
                if (
                  currentComponentIsNextTo(
                    componentHost,
                    experienceFragmentAncestorClassName,
                  )
                ) {
                  // first, at top
                  eventName = "menu_inpage";
                  bloc_name_for_tab_without_proper_heading = document
                    .querySelector<HTMLElement>("#main-content h1")
                    ?.innerText.toLowerCase()
                    .trim();

                  dataToSendForArticle = {
                    bloc_name: "articles",
                    menu_position: article.dataset.filteredIndex,
                    menu_label: `${article.dataset.filters}_${article.querySelector<HTMLElement>(".cmp-teaser__pretitle")?.innerText.trim()}`,
                  };
                } else {
                  // second, at bottom
                  eventName = "bloc_interact";
                  dataToSendForArticle = {
                    bloc_name: "other categories",
                    bloc_interaction: "local discoveries",
                  };
                }
              }
              break;
            }

            case "mercure::magazine::article": {
              if (isEdito) {
                const blocTitle = "other local adresses";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name: blocTitle,
                  bloc_interaction: "see adress page",
                  bloc_type:
                    article
                      .querySelector<HTMLElement>(".cmp-teaser__pretitle")
                      ?.innerText.trim() + `|${articleIndex + 1}`,
                };

                dataToSendForCta = {
                  bloc_name: blocTitle,
                  bloc_interaction: "see more adresses",
                };
              } else {
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name: "hotels nearby",
                  bloc_interaction: "see hotel page",
                  bloc_type: `${this.getHotelRID(article)}|${articleIndex + 1}`,
                };
              }
              break;
            }

            case "mercure::brand::mercureexperience": {
              if (isEdito) {
                if (
                  currentComponentIsChildOf(
                    componentHost,
                    "section-background-container",
                  )
                ) {
                  eventName = "bloc_interact";
                  dataToSendForArticle = {
                    bloc_name: "meeting & events",
                    bloc_interaction: article
                      .querySelector<HTMLElement>(
                        ".ace-button .cmp-button__text",
                      )
                      ?.innerText.trim()
                      .toLowerCase(),
                  };
                }
              }
              break;
            }

            case "mercure::offers::hub": {
              if (isEdito) {
                bloc_name = "offers";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name,
                  bloc_interaction: "see offer page",
                  bloc_type: `${this.getOfferID(article)}|${articleIndex + 1}`,
                };

                dataToSendForCta = {
                  bloc_name,
                  bloc_interaction: "see more offers",
                };
              }
              break;
            }

            case "mercure::offers::details": {
              if (isEdito) {
                bloc_name = "suggested offers";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name,
                  bloc_interaction: "see offer page",
                  bloc_type: `${this.getOfferID(article)}|${articleIndex + 1}`,
                  // @TODO: for now : miss some contrib to be able to check
                };

                dataToSendForCta = {
                  bloc_name,
                  bloc_interaction: "see more offers",
                };
                // @TODO : for now : miss some contrib to be able to check
                // when we'll have contrib, the strings here are good ones
              } else {
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name: "hotels push",
                  bloc_interaction: "see hotel page",
                  bloc_type: `${this.getHotelRID(article)}|${articleIndex + 1}`,
                };
              }
              break;
            }

            case "mercure::destinations::world": {
              if (!isAPI) {
                bloc_name = "continent";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name,
                  bloc_interaction: this.getContinentName(article),
                  bloc_type: (articleIndex + 1).toString(),
                };
              }
              break;
            }

            case "mercure::destinations::continent": {
              if (!isAPI) {
                bloc_name = "country";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name,
                  bloc_interaction: this.getCountryName(article),
                  bloc_type: (articleIndex + 1).toString(),
                };
              }
              break;
            }

            case "mercure::destinations::country": {
              if (!isAPI) {
                const managedData =
                  this.handleCalloutEditoForCountryRegionDistrictCity(
                    article,
                    articleIndex,
                    pageName,
                  );
                eventName = managedData.eventName;
                dataToSendForArticle = managedData.dataToSendForArticle;
                dataToSendForCta = managedData.dataToSendForCta;
              }
              break;
            }

            case "mercure::destinations::department":
            case "mercure::destinations::region":
            case "mercure::destinations::district":
            case "mercure::destinations::city": {
              if (isAPI) {
                bloc_name = "hotels push";
                eventName = "bloc_interact";

                dataToSendForArticle = {
                  bloc_name,
                  bloc_interaction: "see hotel page",
                  bloc_type: `${this.getHotelRID(article)}|${articleIndex + 1}`,
                };

                dataToSendForCta = {
                  bloc_name: `highlighted ${pageName.split("::").pop()}`,
                  bloc_interaction: "see more",
                };
              } else {
                const managedData =
                  this.handleCalloutEditoForCountryRegionDistrictCity(
                    article,
                    articleIndex,
                    pageName,
                  );
                eventName = managedData.eventName;
                dataToSendForArticle = managedData.dataToSendForArticle;
                dataToSendForCta = managedData.dataToSendForCta;
              }
              break;
            }
          }

          // 2 - depending on datafilling : attach events for articles
          if (Object.keys(dataToSendForArticle).length) {
            handleGaTracking({
              node: article,
              htmlEventType: CoreJS.DomEventConstants.CLICK,
              eventName,
              additionalData: { ...dataToSendForArticle },
            });
          }
        });

      // 3.a - depending on datafilling : attach events for ctaButton
      if (ctaButton && Object.keys(dataToSendForCta).length) {
        if (dataToSendForCta.eventName) {
          eventName = dataToSendForCta.eventName;
        }

        handleGaTracking({
          node: ctaButton?.querySelector("a")
            ? ctaButton?.querySelector("a")
            : ctaButton,
          htmlEventType: CoreJS.DomEventConstants.CLICK,
          eventName,
          additionalData: { ...dataToSendForCta },
        });
      }

      // 3.b - depending on tabs presence : attach events for tabs
      if (tabs.length && componentHost.dataset.trackingTabsEvents !== "ready") {
        tabs.forEach((tab, tabIndex) => {
          dataToSendForTab = {
            bloc_name: bloc_name_for_tab_without_proper_heading || bloc_name,
            menu_position: tabIndex + 1,
            menu_label: tab
              .querySelector<HTMLElement>(
                ".ace-callout-container-filters__tab-title",
              )
              ?.innerText.trim()
              .toLowerCase(),
          };

          handleGaTracking({
            node: tab,
            htmlEventType: CoreJS.DomEventConstants.CLICK,
            eventName,
            additionalData: { ...dataToSendForTab },
          });
        });

        componentHost.dataset.trackingTabsEvents = "ready";
      }

      // avoid to add multiple event listeners when the same component is twice (or more) on the page
      componentHost.dataset.tracking = "ready";
    }
  }

  getHotelRID(article: HTMLElement) {
    return (
      article.dataset.productId ||
      article.querySelector<HTMLElement>("*[data-product-id]")?.dataset
        .productId
    );
  }

  getOfferID(article: HTMLElement) {
    return article.dataset.marketingOfferName;
  }

  getContinentName(article: HTMLElement) {
    const targetedPattern = /-c\d{2,9}\.html/;
    const baseString = article.querySelector<HTMLAnchorElement>(
      ".cmp-teaser__pretitle a",
    )?.href;
    return baseString?.split("/continent/hotels-")[1].split(targetedPattern)[0];
  }

  getCountryName(article: HTMLElement) {
    const targetedPattern = /-p[a-z]{2,9}\.html/;
    const baseString = article.querySelector<HTMLAnchorElement>(
      ".cmp-teaser__pretitle a",
    )?.href;
    return baseString?.split("/country/hotels-")[1].split(targetedPattern)[0];
  }

  getSubCountryCategoryName(article: HTMLElement) {
    const baseString = article.querySelector<HTMLAnchorElement>(
      ".cmp-teaser__pretitle a",
    )?.href;
    return baseString?.split("/destination/")[1]?.split("/")[0];
  }

  getSubCountryName(article: HTMLElement, subCountryCategoryName: string) {
    const targetedPattern = /-[a-z0-9]{2,9}\.html/;
    const baseString = article.querySelector<HTMLAnchorElement>(
      ".cmp-teaser__pretitle a",
    )?.href;
    return baseString
      ?.split(`${subCountryCategoryName}/hotels-`)[1]
      ?.split(targetedPattern)[0];
  }

  handleCalloutEditoForCountryRegionDistrictCity(
    article: HTMLElement,
    articleIndex: number,
    pageName: string,
  ) {
    const eventName = "bloc_interact";
    let dataToSendForArticle: object = {};
    let dataToSendForCta: object = {};
    // exception here : in this page, we can have cohabitation of :
    // links to regions/districts   (with subCountryCategoryName)
    // direct links to hostels on ALL website (without subCountryCategoryName)

    const subCountryCategoryName = this.getSubCountryCategoryName(article);

    if (subCountryCategoryName) {
      dataToSendForArticle = {
        bloc_name: subCountryCategoryName,
        bloc_interaction: this.getSubCountryName(
          article,
          subCountryCategoryName,
        ),
        bloc_type: (articleIndex + 1).toString(),
      };
    } else {
      dataToSendForArticle = {
        bloc_name:
          article
            .querySelector<HTMLAnchorElement>(".cmp-teaser__pretitle a")
            ?.innerText.trim() || "",
      };
    }

    dataToSendForCta = {
      bloc_name: `highlighted ${pageName.split("::").pop()}`,
      bloc_interaction: "see more",
    };

    return {
      eventName,
      dataToSendForArticle,
      dataToSendForCta,
    };
  }

  enrichFilteredItems(articles: NodeListOf<HTMLElement>) {
    // 1 - make filters list
    const filters = Array.from(articles)
      .map((article) => article.dataset.filters)
      .reduce((acc, filter) => {
        if (filter && !acc.includes(filter)) {
          acc.push(filter);
        }
        return acc;
      }, []);

    // 2 - loop on that filters list to check each article's filtering and attribute it the good filtered index as data attribute
    filters.forEach((currentFilter) => {
      Array.from(articles)
        .filter((article) => article.dataset.filters === currentFilter)
        .forEach(
          (articleFiltered, articleFilteredIndex) =>
            (articleFiltered.dataset.filteredIndex = (
              articleFilteredIndex + 1
            ).toString()),
        );
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  MercureLoyalty.CLASS_NAMESPACE,
  MercureLoyalty,
  true,
);
