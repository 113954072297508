import {
  handleGaTracking,
  getDataLayer,
} from "../../../../../../utils/tracking";

class ParagraphMercure extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "paragraph";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    const { pageName } = getDataLayer();

    const button: HTMLElement | null =
      componentHost.querySelector(".ace-button a");

    if (button) {
      switch (pageName) {
        case "mercure::offers::details":
          handleGaTracking({
            node: button,
            htmlEventType: CoreJS.DomEventConstants.CLICK,
            eventName: "bloc_interact",
            additionalData: {
              bloc_name: "offer detail",
              bloc_interaction: "more details",
            },
          });

          break;
      }
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  ParagraphMercure.CLASS_NAMESPACE,
  ParagraphMercure,
  true,
);
