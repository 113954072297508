import {
  handleGaTracking,
  getDataLayer,
} from "../../../../../../utils/tracking";

class MercureHeading extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "heading";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    const { pageName } = getDataLayer();

    const button: HTMLElement | null =
      componentHost.querySelector(".cmp-button");

    if (button && pageName === "mercure::loyalty") {
      handleGaTracking({
        node: button,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "cta_enroll",
        additionalData: {
          cta_enroll_context: "loyalty",
          cta_name: button.innerText.trim().toLowerCase(),
          contexte: "1",
        },
      });
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  MercureHeading.CLASS_NAMESPACE,
  MercureHeading,
  true,
);
