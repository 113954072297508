import {
  handleGaTracking,
  getDataLayer,
} from "../../../../../../utils/tracking";

class HeadingOfferMercure extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "heading-offer";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    const { pageName } = getDataLayer();

    const salesButton: HTMLElement | null = componentHost.querySelector(
      ".ace-highlight__content--link",
    );

    if (salesButton) {
      switch (pageName) {
        case "mercure::offers::details":
          handleGaTracking({
            node: salesButton,
            htmlEventType: CoreJS.DomEventConstants.CLICK,
            eventName: "bloc_interact",
            additionalData: {
              bloc_name: "immersive",
              bloc_interaction: "see offer",
              bloc_type: "[offerID]",
            },
          });

          break;
      }
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  HeadingOfferMercure.CLASS_NAMESPACE,
  HeadingOfferMercure,
  true,
);
