import { handleGaTracking } from "../../../../../../utils/tracking";

class MercureLoyaltyLoginBlock extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "loyalty-login-block";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    const ctaElement = componentHost.querySelector<HTMLElement>(
      ".ace-loyalty__button a",
    );

    handleGaTracking({
      node: ctaElement,
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "cta_enroll",
      additionalData: {
        enroll_context: "all member offer",
        cta_name: ctaElement?.innerText.trim(),
      },
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  MercureLoyaltyLoginBlock.CLASS_NAMESPACE,
  MercureLoyaltyLoginBlock,
  true,
);
