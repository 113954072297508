import {
  handleGaTracking,
  getDataLayer,
} from "../../../../../../utils/tracking";

class GoogleMapMercure extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "location";

  constructor(componentHost: HTMLElement) {
    super(componentHost);

    const { pageName } = getDataLayer();

    const buttons: NodeListOf<HTMLElement> = componentHost.querySelectorAll(
      ".map .map-hotel-block",
    );

    if (buttons.length) {
      buttons.forEach((button) => {
        const hotelRID: string | undefined = button
          .querySelector("a")
          ?.href.split("destination=")[1]
          ?.split("&")[0];

        switch (pageName) {
          case "mercure::magazine::article":
            handleGaTracking({
              node: button.querySelector("a"),
              htmlEventType: CoreJS.DomEventConstants.CLICK,
              eventName: "bloc_interact",
              additionalData: {
                bloc_name: "location",
                bloc_interaction: "see hotel page",
                bloc_type: hotelRID,
              },
            });

            break;
        }
      });
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  GoogleMapMercure.CLASS_NAMESPACE,
  GoogleMapMercure,
  true,
);
