// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.svg";

// ACE Library
import "@accor/ace-ui-core";

// COMMON Components
import "./components/common/loyalty/v1/js/loyalty";
import "./components/common/loyalty-login-block/v1/js/loyalty-login-block";
import "./components/common/social-media/v1/js/social-media";
import "./components/common/faq/v1/js/faq";
import "./components/common/core-booking-engine/v1/js/core-booking-engine";
import "./components/common/callouts/v2/callout/js/callout";
import "./components/common/heading/v1/js/heading";
import "./components/common/call-to-action/v1/js/call-to-action";
import "./components/common/googlemap/v1/js/googlemap";
import "./components/common/heading-offer/v1/js/heading-offer";

// CONTENT Components
import "./components/content/highlight/v1/js/highlight";
import "./components/content/navigation-header/v1/js/navigation-header";
import "./components/content/slideshow-carousel/v1/js/slideshow-carousel";
import "./components/content/dynamic-media/js/dynamic-media";
import "./components/content/footer/v1/js/footer";
import "./components/content/paragraph/v1/js/paragraph";

// STRUCTURE Components
import "./components/structure/base-page-template/v1/components/base-page-template";

document.dispatchEvent(
  new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT),
);
