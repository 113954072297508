// File duplicated and slightly updated from https://gitlab.softfactory-accor.net/ecom/aem/custom/applications/ibis/-/blob/build-qa/ibis-site-template/theme/src/components/common/core-booking-engine/v1/js/core-booking-engine.js?ref_type=heads

import { TagManager, CoreBookingEngine } from "@accor/ace-ui-core";

import {
  selectors,
  events,
  errrorTypes,
  handleFocusButtonEvents,
} from "./core-booking-engine.helpers";
class MercureCoreBookingEngine extends CoreBookingEngine {
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.componentHost
      .querySelector(selectors.optionsTriggerBtn)
      ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        if (event?.target?.getAttribute("aria-expanded") == "true") {
          this.tagManagerHandler(events.openAdvancedBookingForm);
        }
      });

    this.focusButtonEvents();
  }

  tagManagerHandler(eventName) {
    const dataLayerValue = document
      .querySelector(selectors.dataLayer)
      ?.getAttribute("data-cmp-datalayer");

    if (dataLayerValue) {
      const pagename = JSON.parse(dataLayerValue)?.pageName;
      const event = {
        name: "",
        data: {},
      };
      window.dataLayer.push({ event_data: null });

      switch (eventName) {
        case events.openAdvancedBookingForm: {
          this.gaEventName = "";

          event.name = "booking_form_interact";
          event.data = {
            pagename,
            form_action:
              window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md
                ? "openadvanced"
                : "show",
            bloc_name: "special rates and accessibility",
            error_type: errrorTypes.blank,
            error_field: "access code",
          };
          break;
        }

        case events.submitBookingForm: {
          this.gaEventName = "";

          const totalRooms = parseInt(
            this.componentHost.querySelector(selectors.roomInput)?.value,
          );
          const arrivalDates = this.componentHost
            .querySelector(selectors.arrivalDates)
            ?.getAttribute("value");
          const accomodationType = this.componentHost
            .querySelector(selectors.accomodationType)
            ?.getAttribute("value");
          const departureDate = this.componentHost
            .querySelector(selectors.departureDate)
            ?.getAttribute("value");
          const numberOfNights = this.componentHost
            .querySelector(selectors.numberOfNights)
            ?.getAttribute("value");

          const arrivalDatesFormat = new Date(arrivalDates);
          const eventDate = new Date();
          let leadTimeDate = "";

          if (arrivalDates && arrivalDates != "") {
            const diffTime = Math.abs(arrivalDatesFormat - eventDate);
            leadTimeDate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          }

          event.name = "booking_form_submit";
          event.data = {
            pagename,
            accomodation_type: accomodationType,
            arrival_dates: arrivalDates,
            departure_date: departureDate,
            number_of_rooms: totalRooms,
            adults_nb: this.totalAdults,
            children_nb: this.totalChildren,
            lead_time: leadTimeDate,
            night_nb: numberOfNights,
          };
          break;
        }

        case events.errorBookingForm: {
          this.gaEventName = "error";
          event.name = "booking_form_interact";

          this.errorFieldForOptions();

          const countArray = [];
          const eventFormErrorBlank = [];
          let eventFormErrorInvalid = [];
          this.event_form_errors_type?.forEach((item, index) => {
            if (item == "blank") {
              countArray.push(index);
            }
          });
          this.event_form_errors?.forEach((object, index) => {
            if (countArray?.length > 0) {
              countArray.forEach((item) => {
                if (item == index) {
                  eventFormErrorBlank.push(object);
                } else {
                  if (!eventFormErrorInvalid.includes(object)) {
                    eventFormErrorInvalid.push(object);
                  }
                }
              });
            } else {
              eventFormErrorInvalid.push(object);
            }
          });
          eventFormErrorInvalid = eventFormErrorInvalid.filter((el) => {
            return !eventFormErrorBlank.includes(el);
          });

          if (eventFormErrorBlank?.length > 0) {
            const eventFieldsBlank = `${eventFormErrorBlank?.toString()?.replaceAll(",", "|")}`;
            const datasEventBlank = {
              event: "GA4event",
              eventName: event.name,
              event_data: {
                pagename,
                form_action: "validate search",
                bloc_name: "search",
                error_type: errrorTypes.blank,
                error_field: eventFieldsBlank,
              },
            };
            TagManager.trackEvent(datasEventBlank);
          }
          if (eventFormErrorInvalid?.length > 0) {
            const eventFieldsInvalid = `${eventFormErrorInvalid?.toString()?.replaceAll(",", "|")}`;

            const datasEventInvalid = {
              event: "GA4event",
              eventName: event.name,
              event_data: {
                pagename,
                form_action: "validate search",
                bloc_name: "search",
                error_type: errrorTypes.invalidValue,
                error_field: eventFieldsInvalid,
              },
            };
            TagManager.trackEvent(datasEventInvalid);
          }
          break;
        }
      }

      const datasEvent = {
        event: "GA4event",
        eventName: event.name,
        event_data: event.data,
      };

      if (datasEvent.eventName != "" && this.gaEventName != "error") {
        // disable event triggering from core
        window.dataLayer.push({ event_data: null });
        TagManager.trackEvent(datasEvent);
      }
    }
  }

  errorFieldForOptions() {
    const regEx = /^[0-9a-zA-Z]+$/;
    this.moreOptionsInputs?.forEach((input) => {
      if (input.value.length > 1) {
        switch (input.name) {
          case "identification.fidelityCard.number": {
            if (!input.value.match(regEx) || input.value.length != 16) {
              this.errorFieldOptionValuePush("loyalty or subscription number");
            }
            break;
          }
          case "identification.reserverId": {
            if (!input.value.match(regEx) || input.value.length > 50) {
              this.errorFieldOptionValuePush("business client with contract");
            }
            break;
          }
          case "identification.reserverContract": {
            if (!input.value.match(regEx) || input.value.length != 10) {
              this.errorFieldOptionValuePush("access code");
            }
            break;
          }
          case "identification.preferredCode.code": {
            if (!input.value.match(regEx) || input.value.length <= 50) {
              this.errorFieldOptionValuePush("preferential code");
            }
            break;
          }
        }
      }
    });
  }

  // Error fields for options value
  errorFieldOptionValuePush(data) {
    if (this.event_form_errors.includes("other")) {
      this.event_form_errors.pop();
      this.event_form_errors_type.pop();
    }
    if (!this.event_form_errors.includes(data)) {
      this.event_form_errors.push(data);
      this.event_form_errors_type.push("invalid value");
    }
  }

  focusButtonEvents() {
    const submitBtn = this.componentHost.querySelector(selectors.submitBtn);
    const inputToFocus = this.componentHost.querySelector(
      selectors.firstTextInput,
    );

    handleFocusButtonEvents({
      submitBtn,
      inputToFocus,
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  CoreBookingEngine.CLASS_NAMESPACE,
  MercureCoreBookingEngine,
  true,
);
