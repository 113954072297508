import { handleGaTracking } from "../../../../../utils/tracking";

class MercureDynamicMedia extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "dynamic-media";

  constructor(componentHost: HTMLElement) {
    super(componentHost);
    this.componentHost = componentHost;
    this.videoPlayer = this.findPlayer();

    if (this.videoPlayer) {
      this.attachVideoPlayerEvent();
    } else {
      this.mutationManager();
    }
  }

  findPlayer() {
    return this.componentHost.querySelector("video") as HTMLMediaElement;
  }

  mutationManager() {
    const mutationConfig = { attributes: true, childList: true, subtree: true };
    this.observer = new MutationObserver(this.mutationCallback.bind(this));
    this.observer.observe(this.componentHost, mutationConfig);
  }

  mutationCallback() {
    this.videoPlayer = this.findPlayer();

    if (this.videoPlayer) {
      this.observer.disconnect();
      this.attachVideoPlayerEvent();
    }
  }

  attachVideoPlayerEvent() {
    handleGaTracking({
      node: this.videoPlayer,
      htmlEventType: "play",
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "highlighted brand",
        bloc_interaction: "video play",
      },
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  MercureDynamicMedia.CLASS_NAMESPACE,
  MercureDynamicMedia,
  true,
);
