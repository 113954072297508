import { BasePageTemplate } from "@accor/ace-ui-core";

export default class MERCUREBasePageTemplate extends BasePageTemplate {
  constructor(componentHost: HTMLElement) {
    super(componentHost);
    document.addEventListener("DOMContentLoaded", function () {
      const mainContent = document.querySelector("#main-content")!;

      const firstHeading: HTMLElement | null =
        mainContent?.querySelector(".heading");

      if (firstHeading) {
        firstHeading.style.margin = "0";
      }
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  BasePageTemplate.CLASS_NAMESPACE,
  MERCUREBasePageTemplate,
  true,
);
